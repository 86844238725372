import { combineReducers } from 'redux'

import globalMessageReducer from './slices/message'
import userDataReducer from './slices/userDataSlice'
import serviceOrderReducer from './slices/selectedSOSlice'

export const rootReducer = combineReducers({
  userDataReducer,
  globalMessageReducer,
  serviceOrderReducer
})
