import { rootReducer } from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage'
import logger from 'redux-logger'

const persisitConfig = {
    key: 'root',
    storage,
    transforms: [
        encryptTransform({
            secretKey: 'R0ot-@-S3cR3t-@-K3Y!',
        }),
    ],

}

const persistedReducer = persistReducer(persisitConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: []
})

const persistor = persistStore(store)

export { store, persistor }