import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null
}


const selectedSOSlice = createSlice({
    name: 'selectedServiceOrder',
    initialState,
    reducers: {

        setId: (state, action) => {
            state.id = action.payload;
        },

        removeId: (state, action) => {
            state.id = null
        }
    }
});


export const { setId, removeId } = selectedSOSlice.actions

export default selectedSOSlice.reducer