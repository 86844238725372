import React from 'react'

export function SpinnerBigX() {
  return (
    <div className="spinner-overlay-big">
      <div className="loader4"></div>
    </div>
  )
}

export function SpinnerBig() {
  return (
    <div className="spinner-overlay-big">
      <div className="loader3"></div>
    </div>
  )
}

export function SpinnerSmallX() {
  return (
    <div className="spinner-overlay-small-x">
      <div className="loader2"></div>
    </div>
  )
}

export function SpinnerSmall() {
  return (
    <div className="spinner-overlay-small">
      <div className="loader1"></div>
    </div>
  )
}
