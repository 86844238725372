import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    type: null,
    message: null,
  },
}

const messageSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setSuccessMessage: ({ data }, { payload }) => {
      if (!data.message) setTimeout(() => (state = initialState), 10) // se ja tiver mensagem, nao coloca outro timeout
      data.type = 'success'
      data.message = payload
    },

    setWarningMessage: ({ data }, { payload }) => {
      if (!data.message) setTimeout(() => (state = initialState), 10)
      data.type = 'warning'
      data.message = payload
    },

    setDangerMessage: ({ data }, { payload }) => {
      if (!data.message) setTimeout(() => (state = initialState), 10)
      data.type = 'danger'
      data.message = payload
    },

    removeMessage: () => {
      state.data = initialState
    },
  },
})

export const { setSuccessMessage, setWarningMessage, setDangerMessage, removeMessage } =
  messageSlice.actions

export default messageSlice.reducer
